<template>
  <div>
    <v-menu
      offset-y
      bottom
      left
      content-class="language-menu"
      absolute
      min-width="150px"
      v-if="$vuetify.display.mdAndUp"
    >
      <template v-slot:activator="{ props }">
        <div class="d-flex align-center mx-3" v-bind="props">
          <img class="mr-2 filter-black" :src="usersIcon" alt="users" />

          <span class="font-14 black--text">{{ userName }}</span>
        </div>
      </template>

      <v-list class="user-menu radius-10" :elevation="0">
        <div
          v-for="(link, i) in menu"
          :key="i"
          @click="goToLink(link.link)"
          class="cursor-pointer custom-list-item d-flex align-center px-2 py-2"
          style="width: 250px"
        >
          <v-icon class="mr-2" size="24" color="black"
            >mdi-{{ link.icon }}</v-icon
          >
          <v-list-item-title class="cursor-pointer font-14">{{
            translate[link.title]
          }}</v-list-item-title>
        </div>
        <div
          @click="goToLink('https://travel.b2bondai.com/admin')"
          class="cursor-pointer custom-list-item d-flex align-center px-2 py-2"
          style="width: 250px"
        >
          <v-icon class="mr-2" size="24" color="black">mdi-account-tie</v-icon>
          <v-list-item-title class="cursor-pointer font-14">{{
            $t("global.admin")
          }}</v-list-item-title>
        </div>

        <div class="line my-1"></div>

        <v-list-item class="cursor-pointer" @click="logoutHandler">
          <div class="d-flex align-center justify-center custom-list-item">
            <v-icon class="cursor-pointer mr-2" size="24" color="black"
              >mdi-logout</v-icon
            >
            <span class="black--text"> {{ translate["navbar.logout"] }} </span>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- user menu mobile -->
    <v-list-group v-if="$vuetify.display.smAndDown" no-action class="px-0">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props">
          <div class="d-flex align-center my-2 cursor-pointer">
            <img src="/images/icons/users.svg" alt="users" />
            <span class="mx-2 font-22 black--text">{{ userName }}</span>

            <img
              class="mx-2"
              width="12px"
              height="12px"
              :src="arrowdownIcon"
              alt="arrow-down"
            />
          </div>
        </v-list-item>
      </template>

      <v-list-item
        v-for="(link, i) in menu"
        :key="i"
        @click="goToLink(link.link)"
        class="cursor-pointer custom-list-item"
      >
        <div class="d-flex align-center">
          <v-icon size="22" color="black">mdi-{{ link.icon }}</v-icon>
          <v-list-item-title class="font-14 mx-2">{{
            translate[link.title]
          }}</v-list-item-title>
        </div>
      </v-list-item>

      <v-list-item
        @click="goToLink('https://travel.b2bondai.com/admin')"
        class="cursor-pointer custom-list-item"
      >
        <div class="d-flex align-center">
          <v-icon size="22" color="black">mdi-account-tie</v-icon>
          <v-list-item-title class="mx-2 font-14">{{
            $t("global.admin")
          }}</v-list-item-title>
        </div>
      </v-list-item>
    </v-list-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import arrowdownIcon from "@/assets/images/icons/arrow-down.svg";
import usersIcon from "@/assets/images/icons/users.svg";
export default {
  name: "App",
  setup() {
    return {
      arrowdownIcon,
      usersIcon,
    };
  },
  data: () => ({
    menu: [
      {
        title: "navbar.myProfile",
        icon: "account-outline",
        link: "https://travel.b2bondai.io/profile/dashboard.xhtml",
      },
      {
        title: "navbar.bookings",
        icon: "bookmark-outline",
        link: "https://travel.b2bondai.io/profile/dashboard.xhtml?selectedSection=BOOKINGS",
      },
      {
        title: "navbar.myIdea",
        icon: "content-save-outline",
        link: "https://travel.b2bondai.io/profile/dashboard.xhtml?selectedSection=IDEAS",
      },
      {
        title: "navbar.myPurchase",
        icon: "content-save-outline",
        link: "https://travel.b2bondai.io/profile/dashboard.xhtml?selectedSection=TEMPORAL_IDEAS",
      },
      {
        title: "navbar.myHolidays",
        icon: "content-save-outline",
        link: "https://travel.b2bondai.io/profile/dashboard.xhtml?selectedSection=HOLIDAY_PACKAGES",
      },
      {
        title: "navbar.myData",
        icon: "information-outline",
        link: "https://travel.b2bondai.io/profile/dashboard.xhtml?selectedSection=DATA",
      },
      {
        title: "navbar.rewards",
        icon: "trophy-outline",
        link: "https://travel.b2bondai.io/profile/dashboard.xhtml?selectedSection=REWARDS_STATEMENT",
      },
    ],
  }),
  computed: {
    ...mapGetters(["translate", "userDetails"]),
    isHomepage() {
      if (this.$route.name == "Homepage") {
        return true;
      } else {
        return false;
      }
    },
    userName() {
      if (localStorage.getItem("userName") || this.userDetails.username) {
        return this.userDetails.agencyName || localStorage.getItem("userName");
      } else {
        return false;
      }
    },
  },
  methods: {
    logoutHandler() {
      this.$emit("loggedOut", true);
    },
    goToLink(url) {
      window.open(url, "_self");
    },
  },
};
</script>

<style lang="scss">
.v-list-item {
  min-height: 40px !important;
  height: 40px !important;
}
.custom-list-item {
  i {
    &::before {
      display: inline-block;
    }
  }
}
</style>
