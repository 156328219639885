<template>
  <div class="terms-policy pb-10">
    <span class="d-block font-24 font-700 black--text">Terms Policy</span>
    <div  v-html="$t('static.terms')"></div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
// useHead({
//   title: "Bondai - Terms",
//   meta: [
//     { name: "description", content: "Custom Terms" },
//     { name: "keywords", content: "Bondai, travel, visit" },
//   ],
// });
</script>

<style lang="scss"></style>
