<template>
  <div class="terms-policy pb-10">
    <span class="d-block font-24 font-700 black--text static.cookiesTitle"
      >Cookies Policy
    </span>
    <div v-html="$t('static.cookies')"></div>
    <div>
      <p class="d-inline-block">
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it in some circumstances. To request to
        review, update, or delete your personal information, please submit a
        request form by clicking
        <span @click="dialog = true" class="primary--text cursor-pointer">
          here
        </span>
      </p>
    </div>

    <v-dialog
      v-model="dialog"
      max-width="700"
      content-class="white radius-15 pa-5 terms-policy__form over-auto"
    >
      <subject-form @close="dialog = false"></subject-form>
    </v-dialog>
  </div>
</template>

<script>
import SubjectForm from "@/components/dialogs/SubjectForm.vue";
export default {
  components: {
    SubjectForm,
  },
  data: () => ({
    dialog: false,
  }),
};
// const { t } = useI18n();
// const contain = t("static.cookies");
// let dialog = ref(false);

// useHead({
//   title: "Bondai - Cookies",
//   meta: [
//     { name: "description", content: "Custom Cookies" },
//     { name: "keywords", content: "Bondai, travel, visit" }
//   ],
// });
</script>

<style lang="scss"></style>
