<template>
  <div class="login-form w-100 h-100">
    <v-row class="w-100 h-100 ma-0" align="center">
      <v-col md="6" v-if="!$vuetify.display.smAndDown" class="pa-0 img-bg h-100 d-flex align-center">
        <div class="px-12">
          <h2 class="font-25 darkblue2--text mt-4">
            {{ translate["register.notRegistered"] }}
          </h2>
          <p class="darkblue2--text font-16">
            {{ translate["register.notRegisteredDesc"] }}
          </p>
          <base-button
            :loading="loading"
            :title="translate['homepage.getStarted']"
            classes="radius-5 mt-6"
            height="40px"
            :outlined="true"
            @click="goToRegister"
          ></base-button>

          <img
            width="300px"
            src="/images/global/login-bg.png"
            alt="login-bg"
          />
        </div>
      </v-col>
      <v-col md="6" class="pa-0" cols="12" v-if="!emailSent">
        <div class="white pa-5">
          <div class="d-flex justify-end mb-7">
            <img
              class="login-form__close cursor-pointer"
              @click="$emit('close')"
              src="/images/icons/close.svg"
              alt="close"
            />
          </div>

          <h3 class="font-32 black--text">
            {{ translate["login.recoverPassword"] }}
          </h3>
          <!-- form -->
          <v-form
            class="mt-6"
            v-model="valid"
            @submit.prevent="submitHandler"
            ref="userForm"
          >
            <!-- first name -->
            <v-row>
              <v-col cols="12">
                <base-input
                  v-model="obj.username"
                  :placeholder="translate['form.usernameOrEmail']"
                  :title="translate['form.usernameOrEmail']"
                  :rules="[
                    (v) =>
                      (!!v && v.length > 3) || translate['rules.fieldRequire'],
                  ]"
                ></base-input>
              </v-col>

              <v-col cols="12">
                <base-button
                  :loading="loading"
                  width="100%"
                  :title="translate['continue']"
                  classes="radius-5  "
                  type="submit"
                  height="44px"
                  color="#FD8467"
                ></base-button>
              </v-col>
              <v-col cols="12" class="text-center">
                <p class="font-16 black--text">
                  {{ translate["login.receiveEmail"] }}
                </p>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-col>

      <v-col md="6" cols="12" class="pa-4" v-if="emailSent">
        <div class="d-flex justify-end mb-7">
          <img
            class="login-form__close cursor-pointer"
            @click="$emit('close')"
            src="/images/icons/close.svg"
            alt="close"
          />
        </div>
        <h3 class="font-32 black--text">
          {{ translate["login.emailSent"] }}
        </h3>
        <div>
          <p class="font-16 black--text">
            {{ translate["login.emailSentDesc1"] }}
            {{ obj.userName }}
            {{ translate["login.emailSentDesc2"] }}
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data: () => ({
    obj: {},
    loading: false,
    emailSent: false,
    valid: false,
  }),
  computed: {
    ...mapGetters(['translate'])
  },
  methods: {
    goToRegister() {
      this.$emit("close");
      this.$router.push('/'+this.$route.params.lang + "/register");
    },
    async submitHandler() {
      let valid = this.$refs.userForm.validate();
      if (!valid) {
        this.$store.dispatch("showSnack", {
          text: this.translate["rules.checkAllFields"],
          color: "error",
        });
        return;
      }
      let reqObj = {
        languageCode: localStorage.getItem("language"),
        emailAddress: this.obj.username,
      };
      this.loading = true;
      let res = await this.$axios.post(
        "/tc-users/send-password-reset-email",
        reqObj,
        {
          params: {
            microsite: 20,
          },
        }
      );
      this.loading = false;
      if (res.data && res.data.success) {
        this.emailSent = true;
      } else {
        this.$store.dispatch("showSnack", {
          text: this.translate["messages.noEmail"],
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.login-form {
  box-shadow: 0px 2px 25px 0px #0000001a;
  border-radius: 24px;
  position: relative;

  &__or {
    &__line {
      background: #dbdde0;
      width: 100%;
      height: 1px;
      margin-top: 2px;
    }
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}
</style>
