<template>
  <div class="drawer">
    <v-app-bar
      prominent
      height="80px"
      class="w-100 d-flex align-center justify-space-between drawer-navbar px-4 py-2"
      elevation="0"
    >
      <div @click="linkHandler('HOME')">
        <img :src="require('@/assets/images/new-design/global/logo-white.svg')" alt="logo" />
      </div>
      <transition name="fade" mode="out-in">
        <v-app-bar-nav-icon
          style="z-index: 999999"
          color="white"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </transition>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :right="$i18n.locale != 'en'"
      :left="$i18n.locale == 'en'"
      height="100vh"
      width="300"
    >
      <v-list class="px-3" nav dense>
        <v-list-item-group>
          <div class="d-flex justify-space-between my-2 cursor-pointer">
            <img
              class="cursor-pointer"
              @click="linkHandler('HOME')"
              :src="logoImage"
              alt="logo"
            />
            <img @click="drawer = !drawer" :src="closeIcon" alt="close" />
          </div>

          <!-- becaome provider -->
          <base-button
            class="w-100 mt-5"
            width="100%"
            elevation="0"
            height="40px"
            :title="translate['navbar.partnerRegistration']"
            @click="$router.push('/' + $route.params.lang + '/register')"
          ></base-button>

          <!-- my account -->
          <base-button
            class="w-100 mt-4 mb-3"
            width="100%"
            elevation="0"
            height="40px"
            :outlined="true"
            :title="translate['form.login']"
            @click="goToLink('https://travel.b2bondai.com/login.xhtml')"
            v-if="!userName || loggedOut"
          ></base-button>

          <!-- logout  -->
          <base-button
            class="w-100 mt-4"
            width="100%"
            elevation="0"
            height="40px"
            :outlined="true"
            title="Logout"
            @click="logoutHandler"
            v-if="userName && !loggedOut"
          ></base-button>
          <v-list-item
            v-for="(link, i) in links"
            :key="i"
            @click="linkHandler(link.section)"
          >
            <span class="font-16 black--text">{{ translate[link.title] }}</span>
          </v-list-item>
          <!-- list group -->

          <languages-component
            @switchLanguage="switchLanguage"
            :selectedLanguage="selectedLanguage"
          ></languages-component>

          <user-menu v-if="userName && !loggedOut"></user-menu>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- login form -->
    <v-dialog
      v-model="loginDialog"
      max-width="1100"
      height="600"
      content-class="radius-25 form-height bg-white "
    >
      <login-form @close="loginDialog = false"></login-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LanguagesComponent from "./components/LanguagesComponent.vue";
import UserMenu from "./components/UserMenu.vue";
import LoginForm from "@/components/dialogs/LoginForm.vue";

// images
import logoImage from "@/assets/images/global/logo.png";
import closeIcon from "@/assets/images/icons/close.svg";
export default {
  name: "App",
  components: { LanguagesComponent, UserMenu, LoginForm },
  setup() {
    return {
      logoImage,
      closeIcon,
    };
  },
  data: () => ({
    drawer: false,
    settingDialog: false,
    registerDialog: false,
    group: "",

    b2bondaiLinks: [
      {
        title: "navbar.home",
        section: "HOME",
        name: "Home",
        active: false,
      },
      {
        title: "navbar.aboutBondai",
        section: "ABOUT",
        name: "About Bondai",
        active: false,
      },
      {
        title: "navbar.ourProduct",
        section: "OUR-PRODUCT",
        name: "Our Product",
        active: false,
      },
      // {
      //   title: vm.$t("Partners"),
      //   section: "PARTNERS",
      //   name: "Partners",
      //   active: false,
      // },
      {
        title: "navbar.services",
        section: "SERVICES",
        name: "Services",
        active: false,
      },
      {
        title: "navbar.contact",
        section: "CONTACT",
        name: "Contact",
        active: false,
      },
    ],
    languages: [
      {
        title: "english",
        value: "en",
        img: "/images/global/en-lang.svg",
      },
      {
        title: "arabic",
        value: "ar",
        img: "/images/global/ar-lang.svg",
      },
    ],
    loginDialog: false,
    loggedOut: false,
    selectedLanguage:
      localStorage.getItem("language") == "ar" ? "arabic" : "english",
  }),
  computed: {
    ...mapGetters(["translate", "userDetails"]),
    links() {
      return this.b2bondaiLinks;
    },
    userName() {
      if (localStorage.getItem("userName") || (this.userDetails &&this.userDetails.username)) {
        return this.userDetails.agencyName || localStorage.getItem("userName");
      } else {
        return false;
      }
    },
  },
  methods: {
    logoutHandler() {
      this.store.setUser({});
      localStorage.setItem("userName", "");
      this.loggedOut = true;
    },
    loginHandler() {
      this.loggedOut = false;
      this.loginDialog = true;
    },
    goToLink(link) {
      window.open(link, "_self");
    },
    linkHandler(section) {
      const top = document.querySelector("#" + section);
      window.scrollTo({
        top: top.offsetTop - 80,
        left: 0,
        behavior: "smooth",
      });
    },
    async switchLanguage(language) {
      this.selectedLanguage = language.title;
      this.$vuetify.rtl = language.value == "ar" ? true : false;
      this.$i18n.locale = language.value;
      localStorage.setItem("language", language.value);
      // Inside a component or method
      const currentParams = this.$route.params;
      const newParams = { ...currentParams, lang: language.value };

      setTimeout(() => {
        if (JSON.stringify(newParams) !== JSON.stringify(currentParams)) {
          this.$router.push({
            name: this.$route.name,
            params: { lang: language.value },
          });
        }
      }, 100);
      await this.$store.dispatch("changeLangHandler");
    },
  },
};
</script>

<style lang="scss">
.v-toolbar__content {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100%;
}
.v-navigation-drawer {
  height: 100vh !important;
  z-index: 9999999999999999999999 !important;
}
.drawer {
  z-index: 9999999999999999999 !important;
  position: relative;

  .drawer-navbar {
    background: transparent !important;
    transition: 0.3s;

    &--scrolled {
      background: rgba(255, 255, 255, 0.6) !important;
    }
  }
  .logo {
    width: 110px !important;
  }
  .v-list {
    background: transparent !important;
    i {
      color: white;
    }
  }
  .v-list-group__header {
    padding-left: 8px !important;
    i {
      color: white !important;
    }
  }
}
</style>
