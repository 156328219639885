<template>
  <div class="reset-password w-100 h-100">
    <div class="max-width">
      <v-row class="w-100 h-100 ma-0" align="center" justify="center">
        <v-col md="6" class="pa-0" cols="12">
          <div class="white pa-5">
            <h3 class="font-32 black--text">
              {{ translate["resetPassword"] }}
            </h3>
            <!-- form -->
            <v-form
              class="mt-6"
              v-model="valid"
              @submit.prevent="submitHandler"
              ref="userForm"
            >
              <!-- first name -->
              <v-row>
                <v-col cols="12" class="pb-0">
                  <base-input
                    v-model="obj.password"
                    placeholder="*********"
                    :title="translate['form.password']"
                    :customIcon="true"
                    :type="showPassword ? 'text' : 'password'"
                    :appendIcon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @appendClicked="showPassword = !showPassword"
                    :hideDetails="false"
                    :rules="passwordRules"
                  ></base-input>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <base-input
                    v-model="confirmPassword"
                    placeholder="*********"
                    :title="translate['form.confirmPassword']"
                    :customIcon="true"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    :appendIcon="
                      showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @appendClicked="showConfirmPassword = !showConfirmPassword"
                    :hideDetails="false"
                    :rules="passwordRules"
                  ></base-input>
                </v-col>

                <v-col cols="12" class="mt-4">
                  <base-button
                    :loading="loading"
                    width="100%"
                    :title="translate['form.resetPassword']"
                    classes="radius-5  "
                    type="submit"
                    height="44px"
                    color="#FD8467"
                  ></base-button>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  setup() {
    // useHead({
    //   title: "Bondai - Reset-Password",
    //   meta: [
    //     { name: "description", content: "Custom Reset Password" },
    // { name: "keywords", content: "Bondai, travel, visit" }
    //   ],
    // });
  },
  data: () => ({
    obj: {},
    loading: false,
    emailSent: false,
    showPassword: false,
    showConfirmPassword: false,
    confirmPassword: false,

    valid: false,
    passwordRules: [
      (v) =>
        (!!v && v.length > 7) ||
        "Password should include at least 8 characters, at least 1 number, at least 1 capital letter",
      (v) =>
        /^(?=.*\d)(?=.*[A-Z]).{8,40}$/.test(v) ||
        "Password should include at least 8 characters, at least 1 number, at least 1 capital letter",
    ],
  }),
  metaInfo() {
    return {
      title: "Reset-Password Title",
      bodyAttrs: {
        tabIndex: 0,
      },
      meta: [
        {
          name: "Reset-Password Description",
          content: "Reset-Password Content",
          vmid: "Reset-Password vmid",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["translate"]),
  },
  methods: {
    goToRegister() {
      this.$emit("close");
      this.$router.push("/" + this.$route.params.lang + "/register");
    },
    async submitHandler() {
      let valid = this.$refs.userForm.validate();
      if (!valid) {
        this.$store.dispatch("showSnack", {
          text: this.translate["rules.checkAllFields"],
          color: "error",
        });
        return;
      }
      if (this.confirmPassword != this.obj.password) {
        this.$store.dispatch("showSnack", {
          text: this.translate["rules.passwordNotConfirm"],
          color: "error",
        });
        return;
      }

      let reqObj = {
        token: this.$route.params.pathMatch,
        newPassword: this.obj.password,
      };
      this.loading = true;
      let res = await this.$axios.post("/tc-users/reset-password", reqObj, {
        params: {
          microsite: 20,
        },
      });
      this.loading = false;
      if (res.data && res.data.success) {
        this.$store.dispatch("showSnack", {
          text: this.translate["messages.passwordUpdated"],
          color: "error",
        });
        this.$router.replace("/");
      } else {
        this.$store.dispatch("showSnack", {
          text: this.translate["messages.noEmail"],
          color: "error",
        });
      }
    },
  },
  created() {},
};
</script>

<style lang="scss">
.reset-password {
  padding: 100px 20px;
}
</style>
