<template>
    <div class="terms-policy pb-10">
      <span class="d-block font-24 font-700 black--text">Privacy Policy</span>
  
      <div class="d-inline-block" v-html="$t('static.privacy')"></div>
      <div>
        <p class="d-inline-block">
          {{ $t("static.privacyHereSentence") }}
          <span @click="dialog = true" class="primary--text cursor-pointer">
            {{ $t("static.here") }}
          </span>
        </p>
      </div>
  
      <v-dialog
        v-model="dialog"
        max-width="700"
        content-class="white radius-15 pa-5 terms-policy__form over-auto"
      >
        <subject-form @close="dialog = false"></subject-form>
      </v-dialog>
    </div>
  </template>
  
  <script >
  import SubjectForm from "@/components/dialogs/SubjectForm.vue";
export default {
  components: {
    SubjectForm,
  },
  data: () => ({
    dialog: false,
  }),
};
  // useHead({
  //   title: "Bondai - Privacy",
  //   meta: [
  //     { name: "description", content: "Custom Privacy" },
  //     { name: "keywords", content: "Bondai, travel, visit" }
  //   ],
  // });
  </script>
  
  <style lang="scss"></style>
  