<template>
  <div class="register mt-10">
    <div class="max-width">
      <v-row>
        <v-col
          cols="12"
          md="7"
          :class="$vuetify.display.smAndDown ? '' : 'px-14'"
        >
          <div :class="$vuetify.display.smAndDown ? '' : 'px-14'">
            <h2 class="font-32 darkblue2--text mb-7">
              {{ translate["form.partnerRegister"] }}
            </h2>
            <v-form
              v-model="valid"
              ref="userForm"
              @submit.prevent="submitHandler"
            >
              <v-row>
                <v-col cols="12">
                  <span class="font-18 font-600 darkblue--text">{{
                    translate["form.companyDetails"]
                  }}</span>
                </v-col>
                <!-- first name -->
                <v-col class="py-0" cols="12">
                  <base-input
                    :placeholder="translate['form.companyNamePlaceholder']"
                    :title="translate['form.companyName']"
                    v-model="obj.companyDetails.name"
                    :hideDetails="false"
                    :defaultSelected="obj.companyDetails.name"
                    @changeSelected="obj.companyDetails.name = $event"
                  ></base-input>
                </v-col>

                <!-- surname -->
                <v-col class="py-0" cols="12">
                  <base-select
                    :placeholder="translate['form.companyTypePlaceholder']"
                    :title="translate['form.companyType']"
                    :items="types"
                    :hideDetails="false"
                    itemText="title"
                    itemValue="value"
                    :normalSelect="true"
                    :defaultSelected="obj.companyDetails.type"
                    @changeSelected="obj.companyDetails.type = $event"
                  ></base-select>
                </v-col>

                <!-- document number-->
                <v-col class="py-0" cols="12">
                  <base-input
                    :placeholder="translate['form.documentNumberPlaceholder']"
                    :title="translate['form.documentNumber']"
                    :hideDetails="false"
                    :defaultSelected="obj.companyDetails.documentNumber"
                    @changeSelected="obj.companyDetails.documentNumber = $event"
                  ></base-input>
                </v-col>

                <!-- email -->
                <v-col class="py-0" cols="12" md="12">
                  <base-input
                    :placeholder="translate['form.companyEmailPlaceholder']"
                    :title="translate['form.companyEmail']"
                    type="email"
                    :hideDetails="false"
                    :defaultSelected="obj.companyDetails.email"
                    @changeSelected="obj.companyDetails.email = $event"
                  ></base-input>
                </v-col>

                <!-- companyPhone -->
                <span class="font-16 d-block mb-1 black--text mx-0 px-3">{{
                  translate["form.companyPhoneNumber"]
                }}</span>
                <v-row class="w-100 px-0 mx-0 mb-3 pb-0">
                  <v-col cols="5" class="pb-0">
                    <v-autocomplete
                      class="mt-2"
                      placeholder="+966"
                      title=""
                      :rules="[
                        (v) =>
                          !!v || v == '0' || translate['messages.required'],
                      ]"
                      v-model="obj.companyDetails.phoneNumber.regionCode"
                      :items="countries"
                      :hideDetails="false"
                      item-text="title"
                      item-value="value"
                    >
                      <template v-slot:item="{ props, item }">
                        <div
                          v-bind="props"
                          class="d-flex align-center px-2 py-1 cursor-pointer phone-list-item"
                          :class="
                            item.raw.value ==
                            obj.companyDetails.phoneNumber.regionCode
                              ? ' text-primary'
                              : ''
                          "
                        >
                          <span>{{ item.raw.title }}</span>
                          <span class="mx-1"> ({{ item.raw.value }})</span>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="7" class="mt-1 pb-0">
                    <base-input
                      :placeholder="translate['form.enterPhone']"
                      title=""
                      type="number"
                      :hideDetails="false"
                      :defaultSelected="
                        obj.companyDetails.phoneNumber.phoneNumber
                      "
                      @changeSelected="
                        obj.companyDetails.phoneNumber.phoneNumber = $event
                      "
                    ></base-input>
                  </v-col>
                </v-row>

                <!-- company address -->
                <v-col class="py-0 relative" cols="12" style="top: -10px">
                  <span class="font-16 d-block mb-1 black--text">{{
                    translate["form.companyAddress"]
                  }}</span>

                  <v-autocomplete
                    :placeholder="translate['form.companyAddress']"
                    :title="translate['form.companyAddress']"
                    v-model="obj.companyDetails.country"
                    :items="coutriesListItems"
                    :hideDetails="false"
                    item-text="title"
                    item-value="value"
                  >
                  </v-autocomplete>
                </v-col>
                <!-- <v-col
                    class="py-0 relative"
                    cols="12"
                    style="top: -10px"
                    :key="postalCodeUpated"
                  >
                    <span class="font-16 d-block mb-1 black--text">{{
                      translate["form.companyAddress"]
                    }}</span>
                    <input
                      id="location"
                      type="select"
                      size="50"
                      :placeholder="translate['form.companyAddressPlaceholder']"
                      autocomplete="on"
                      runat="server"
                      class="location-style relative"
                      ref="locationValue"
                    />
                  </v-col> -->

                <v-col cols="12">
                  <span class="font-18 darkblue--text font-600">{{
                    translate["form.contactPerson"]
                  }}</span>
                </v-col>

                <!-- email -->
                <v-col class="py-0" cols="12" md="6">
                  <base-input
                    :placeholder="translate['form.namePlaceholder']"
                    :title="translate['form.name']"
                    :hideDetails="false"
                    :defaultSelected="obj.userDetails.firstName"
                    @changeSelected="obj.userDetails.firstName = $event"
                  ></base-input>
                </v-col>

                <!-- email -->
                <v-col class="py-0" cols="12" md="6">
                  <base-input
                    :placeholder="translate['form.lastNamePlaceholder']"
                    :title="translate['form.lastName']"
                    :defaultSelected="obj.userDetails.lastName"
                    @changeSelected="obj.userDetails.lastName = $event"
                    :hideDetails="false"
                  ></base-input>
                </v-col>

                <!-- email -->
                <v-col class="py-0" cols="12">
                  <base-input
                    :placeholder="translate['form.emailPlaceholder']"
                    :title="translate['form.email']"
                    :rules="emailRules"
                    type="email"
                    :defaultSelected="obj.userDetails.email"
                    @changeSelected="obj.userDetails.email = $event"
                    :hideDetails="false"
                  ></base-input>
                </v-col>

                <!-- password -->
                <v-col class="py-0" cols="12">
                  <div class="d-flex align-center">
                    <base-input
                      :placeholder="translate['form.passwordPlaceholder']"
                      :title="translate['form.password']"
                      :defaultSelected="obj.userDetails.password"
                      @changeSelected="obj.userDetails.password = $event"
                      :customIcon="true"
                      :appendIcon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @appendClicked="showPassword = !showPassword"
                      :hideDetails="false"
                      :rules="[
                        rulesObj.uppercase,
                        rulesObj.special,
                        rulesObj.number,
                        rulesObj.length,
                        rulesObj.lowercase,
                      ]"
                      class="w-100"
                    ></base-input>

                    <v-tooltip>
                      <template v-slot:activator="{ props }">
                        <v-icon
                          class="mt-3 ml-2"
                          v-bind="props"
                          color="grey-lighten-1"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                      </template>
                      <div class="mx-2">
                        <div
                          class="mb-1"
                          v-for="(rule, i) in passwordRules"
                          :key="i"
                        >
                          <v-icon
                            v-bind="props"
                            :color="rule.checked ? '#18A558' : 'error'"
                          >
                            mdi-{{
                              rule.checked
                                ? "check-circle-outline"
                                : "close-circle-outline"
                            }}
                          </v-icon>
                          <span class="ml-2 font-14">{{ $t(rule.title) }}</span>
                        </div>
                      </div>
                    </v-tooltip>
                  </div>
                </v-col>

                <!-- phonenumber -->
                <span class="font-16 d-block mb-1 black--text mx-0 px-3">{{
                  translate["form.phoneNumber"]
                }}</span>
                <v-row class="w-100 px-0 mx-0">
                  <v-col cols="5" class="mt-1">
                    <v-autocomplete
                      placeholder="+966"
                      title=""
                      :rules="[(v) => !!v || v == '0' || '']"
                      v-model="obj.userDetails.phoneNumber.regionCode"
                      :items="countries"
                      :hideDetails="false"
                      item-text="title"
                      item-value="value"
                    >
                      <template v-slot:item="{ props, item }">
                        <div
                          v-bind="props"
                          class="d-flex align-center px-2 py-1 cursor-pointer phone-list-item"
                          :class="
                            item.raw.value ==
                            obj.userDetails.phoneNumber.regionCode
                              ? ' text-primary'
                              : ''
                          "
                        >
                          <span>{{ item.raw.title }}</span>
                          <span class="mx-1"> (+{{ item.raw.value }})</span>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="7">
                    <base-input
                      :placeholder="translate['form.enterPhone']"
                      title=""
                      :rules="[]"
                      type="number"
                      :hideDetails="false"
                      :defaultSelected="obj.userDetails.phoneNumber.phoneNumber"
                      @changeSelected="
                        obj.userDetails.phoneNumber.phoneNumber = $event
                      "
                    ></base-input>
                  </v-col>
                </v-row>

                <v-col cols="12">
                  <p class="font-14">
                    {{ translate["form.check2Desc"] }}
                  </p>
                </v-col>

                <v-col cols="12">
                  <p class="font-14">
                    {{ translate["form.check1Desc"] }}
                  </p>
                  <v-checkbox
                    :hide-details="false"
                    dense
                    v-model="obj.hubSpot.consentToProcess"
                    class="mt-1"
                    :class="$vuetify.rtl ? 'text-right' : ''"
                    :rules="[(v) => !!v || 'please accept to continue']"
                    color="primary"
                  >
                    <template v-slot:label>
                      <p
                        class="font-14 mt-1"
                        v-html="translate['form.check1']"
                      ></p>
                    </template>
                  </v-checkbox>
                  <v-checkbox
                    :hide-details="true"
                    dense
                    class="text-black"
                    v-model="obj.hubSpot.communication"
                    :label="translate['form.check2']"
                    :class="$vuetify.rtl ? 'text-right' : ''"
                    color="primary"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12">
                  <base-button
                    :loading="loading"
                    width="100%"
                    :title="translate['register']"
                    classes="radius-5 mt-4 "
                    type="submit"
                    height="46"
                    color="#FD8467"
                  ></base-button>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>
        <v-col cols="12" md="5" v-if="!$vuetify.display.smAndDown">
          <div class="register-cover radius-25 px-6 pt-10">
            <div class="text-center">
              <img
                width="250"
                height="250"
                class="mb-6"
                :src="loginBg"
                alt="login-bg"
              />
              <h3
                class="register-cover__text font-32"
                v-html="translate['form.sideTitle']"
              ></h3>
            </div>
            <p class="font-16 justify-stretch mt-6">
              {{ translate["form.sideDesc1"] }}
            </p>
            <p class="font-16 mt-4">
              {{ translate["form.sideDesc2"] }}
            </p>

            <homepage-about
              class="register-cover__details"
              :isHomepage="false"
            ></homepage-about>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import countriesCode, { countrylist } from "@/plugins/countries";

import axios from "axios";
import { mapGetters } from "vuex";
import loginBg from "@/assets/images/global/login-bg.png";

export default {
  setup() {
    let countries = countriesCode;
    // useHead({
    //   title: "Bondai - Register",
    //   meta: [
    //     { name: "description", content: "Custom Register" },
    //     { name: "keywords", content: "Bondai, travel, visit" },
    //   ],
    //   script: [
    //     {
    //       src: "https://maps.googleapis.com/maps/api/js?key=AIzaSyByfb1gIeu_XvT1yrPm49y34sJ25HYYN5Q&libraries=places",
    //     },
    //   ],
    // });

    return { countries, loginBg };
  },
  metaInfo() {
    return {
      title: "Register Title",
      bodyAttrs: {
        tabIndex: 0,
      },
      meta: [
        {
          name: "Register Description",
          content: "Register Content",
          vmid: "Register vmid",
        },
      ],
    };
  },
  data: () => ({
    emailRules: [
      (v) => !!v || "Make sure the email address you entered is correct",
      (v) =>
        /.+@.+\..+/.test(v) ||
        "Make sure the email address you entered is correct",
    ],
    passwordRules: [
      { title: "auth.length", checked: true },
      { title: "auth.containUppercase", checked: true },
      { title: "auth.containLowercase", checked: false },
      { title: "auth.oneSymbol", checked: false },
      { title: "auth.containNumbers", checked: false },
    ],
    rulesObj: {
      length: (value) => {
        return (value && value.length >= 6) || "Min password length: 8 symbols"; //eslint-disable-line
      },
      uppercase: (value) => {
        return (
          (value && value.toLowerCase() !== value) ||
          "At least 1 uppercase letter"
        );
      },
      lowercase: (value) => {
        return (
          (value && value.toUpperCase() !== value) ||
          "At least 1 lowercase letter"
        );
      },
      number: (value) => {
        return /\d/.test(value) || "At least 1 number"; //eslint-disable-line
      },
      special: (value) => {
        return /[!sS@#\$%\^&\*]/.test(value) || "At least 1 special symbol"; //eslint-disable-line
      },
    },
    valid: false,
    loading: false,
    showPassword: false,
    postalCodeUpated: false,
    obj: {
      userDetails: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phoneNumber: { phoneNumber: "" },
      },
      companyDetails: {
        name: "",
        type: null,
        documentNumber: "",
        email: "",
        phoneNumber: { phoneNumber: "" },
      },
      hubSpot: {
        pageName: "Register",
        pageUri: "https://b2bondai.com/register",
        consentToProcessText:
          "I agree to allow Bondai to store and process my personal data.",
        communicationText:
          "I agree to receive other communications from Bondai.",
      },
    },
  }),
  computed: {
    ...mapGetters(["translate"]),

    coutriesListItems() {
      return countrylist;
    },
    types() {
      return [
        { title: this.translate["form.types.travelAgency"], value: 10 },
        {
          title: this.translate["form.types.destinationManagementCompany"],
          value: 30,
        },

        { title: this.translate["form.types.hotelier"], value: 40 },
        { title: this.translate["form.types.corporateCompany"], value: 50 },
        { title: this.translate["form.types.startUp"], value: 60 },
      ];
    },
  },
  watch: {
    obj: {
      handler() {
        // uppercase
        let special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/; //eslint-disable-line
        if (
          this.obj.userDetails.password !=
          this.obj.userDetails.password.toLowerCase()
        ) {
          this.passwordRules[1].checked = true;
        } else {
          this.passwordRules[1].checked = false;
        }
        // lowercase
        if (
          this.obj.userDetails.password !=
          this.obj.userDetails.password.toUpperCase()
        ) {
          this.passwordRules[2].checked = true;
        } else {
          this.passwordRules[2].checked = false;
        }
        // number
        if (/\d/.test(this.obj.userDetails.password)) {
          this.passwordRules[4].checked = true;
        } else {
          this.passwordRules[4].checked = false;
        }
        // special
        if (special.test(this.obj.userDetails.password)) {
          this.passwordRules[3].checked = true;
        } else {
          this.passwordRules[3].checked = false;
        }

        // length
        if (this.obj.userDetails.password.length > 5) {
          this.passwordRules[0].checked = true;
        } else {
          this.passwordRules[0].checked = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    async submitHandler() {
      let { valid } = await this.$refs.userForm.validate();
      let hasError = false;
      let objReq = this.obj;
      if (!objReq.hubSpot.consentToProcess) {
        this.$store.dispatch("showSnack", {
          text: "Please accept terms and conditions",
          color: "error",
        });
        hasError = true;
      }
      if (!valid || !objReq.userDetails.phoneNumber.regionCode) {
        this.$store.dispatch("showSnack", {
          text: this.translate["rules.checkAllFields"],
          color: "error",
        });
        hasError = true;
      }
      // if (!objReq.companyDetails.googlePlaceId) {
      //   this.store.showSnack({
      //     text: this.translate["messages.checkCountry"],
      //     color: "error",
      //   });
      //   hasError = true;
      // }
      if (hasError) {
        setTimeout(() => {
          document
            .querySelectorAll(".v-input--error")[0]
            .scrollIntoView({ behavior: "smooth", block: "center" });
        }, 100);
        return;
      }
      if (this.getCookie("hubspotutk")) {
        objReq.hubSpot.userToken = this.getCookie("hubspotutk");
      }
      this.loading = true;
      let res = await axios.post("/tc-users/register-partner", objReq);
      this.loading = false;
      if (res.data && res.data.success) {
        this.$store.dispatch("showSnack", {
          text: this.translate["messages.userCreated"],
          color: "success",
        });
        setTimeout(() => {
          this.$router.push("/" + this.$route.params.lang);
        }, 2000);
      } else {
        let error = "";
        if (res.Errors && res.Errors.length > 0) {
          error = this.translate[res.Errors[0].Message];
        }
        if (res.errors && res.errors.length > 0) {
          error = this.translate[res.errors[0].message];
        }
        this.$store.dispatch("showSnack", {
          text: error,
          color: "error",
        });
      }
    },
    initialize() {
      var input = document.getElementById("location");
      var autocomplete = new window.google.maps.places.Autocomplete(input);
      window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        () => {
          var place = autocomplete.getPlace();
          this.obj.companyDetails.googlePlaceId = place.place_id;
        }
      );
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
  mounted() {
    setTimeout(() => {
      this.initialize();
    }, 300);
  },
};
</script>

<style lang="scss">
.register {
  padding: 100px 20px 0px 20px;

  .v-input__slot {
    align-items: start;
  }
  .register-cover {
    background: #fef3d5;
    padding-bottom: 150px;

    &__text {
      color: #2e333b;
    }

    &__details {
      margin-top: 100px;
    }
  }
  .input-style .v-input__slot {
    border-radius: 3px;
  }
  .location-style {
    border: 0px;
    box-shadow: 0px 2px 10px rgba(168, 168, 168, 0.05);
    width: 100%;
    padding: 0px 10px;
    border-radius: 0px;
    background: #ededee;
    border-bottom: 1px solid #858585;
    height: 58px !important;
    &::placeholder {
      color: rgb(167, 167, 167);
    }
  }
}
.phone-list-item {
  transition: 0.3s;

  &:hover {
    background: rgb(212, 212, 212);
  }
}
.v-list-item--active {
  // background-color: $primary;
  background-color: orange;
}
</style>
