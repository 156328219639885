<template>
    <div class="pt-14 mt-14">
      <contact-section ></contact-section>
    </div>
  </template>
  
  <script>
  import ContactSection from '../homepage/components/ContactSection.vue'
  export default{
    components:{
      ContactSection
    }
  }
  // useHead({
  //   title: "Bondai - Contact",
  //   meta: [
  //     { name: "description", content: "Custom Contact" },
  //     { name: "keywords", content: "Bondai, travel, visit" }
  //   ],
  // });
  </script>
  
  <style></style>
  