<template>
  <div class="hero-section custom-zoom">
    <div class="hero-section__content">
      <img
        class="hero-section__bottom-cave"
        :src="require('@/assets/images/new-design/hero/cave.svg')"
      />
      <img
        class="hero-section__top-cave"
        :src="require('@/assets/images/new-design/hero/cave-top.svg')"
      />
      <img
        class="hero-section__top-right"
        :src="require('@/assets/images/new-design/hero/top-right.png')"
      />
      <img
        class="hero-section__bottom-left"
        :src="require('@/assets/images/new-design/hero/bottom-left.png')"
      />
      <img
        class="hero-section__bottom-center"
        :src="require('@/assets/images/new-design/hero/bottom-center.png')"
      />
      <img
        class="hero-section__bottom-right"
        :src="require('@/assets/images/new-design/hero/bottom-right.png')"
      />
      <div class="max-width h-100">
        <v-row
          align="center"
          :class="$vuetify.display.smAndDown ? 'col-reverse' : ''"
        >
          <v-col cols="12" md="6">
            <div class="hero-section__text z-9 relative">
              <h1
                class="font-72 font-700 d-block nunito-extra-Bold text-white"
                :class="$vuetify.locale.isRtl ? 'pl-5' : 'pr-5'"
                v-html="translate['header.title']"
              ></h1>
              <p class="font-16 mt-4 text-white">
                {{ translate["header.subTitle"] }}
              </p>

              <div>
                <v-btn
                  color="primary"
                  elevation="0"
                  class="radius-30 mt-5 btn-hover"
                  :class="$vuetify.display.mdAndDown ? '  mt-3' : ' '"
                  height="50px"
                  @click="$router.push('/' + $route.params.lang + '/register')"
                >
                  <span
                    class="font-16 d-inline-block mb-1 nunito-sans-Bold text-black font-600"
                    >{{ translate["navbar.partnerRegistration"] }}</span
                  >
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="relative hero-section__main">
          <img
            class="hero-section__bg-main-img"
            :src="require('@/assets/images/new-design/hero/bg-hero-img.svg')"
          />
          <img
            class="hero-section__main-img"
            :src="require('@/assets/images/new-design/hero/hero-img.webp')"
            alt="hero-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  setup() {
    return {};
  },
  data: () => ({
    embedDialog: false,
  }),
  computed: {
    ...mapGetters(["translate"]),
  },
  methods: {
    goToLink(link) {
      window.open(link, "_self");
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.hero-section__main {
  z-index: 9;
  position: relative;
  &:hover {
    .hero-section__bg-main-img {
      opacity: 1 !important;
    }
  }
}
.hero-section__bottom-right:hover ~ .max-width .hero-section__main .hero-section__bg-main-img {
  opacity: 1 !important;
}
.hero-section {
  position: relative;
  background-color: #0f1114;
  background-image: url("@/assets/images/new-design/hero/bg-lines.svg");
  background-size: cover;
  overflow: hidden;

  &__content {
    max-width: 3000px;
    margin: auto;
    position: relative;
    padding-top: 60px;
  }

  &__top-left {
    position: absolute;
    top: 0px;
    z-index: 5;
    left: 0px;
  }
  &__top-right {
    position: absolute;
    top: 0px;
    z-index: 5;
    right: 0px;
  }
  &__bottom-left {
    position: absolute;
    bottom: 0px;
    z-index: 5;
    left: 0px;
  }
  &__bottom-center {
    position: absolute;
    bottom: -10%;
    z-index: 5;
    left: 48%;
    transform: translateX(-50%);
    width: 700px;
  }
  &__bottom-cave {
    position: absolute;
    bottom: 0px;
    z-index: 5;
    width: 100%;
  }
  &__top-cave {
    position: absolute;
    top: 0px;
    z-index: 6;
    width: 100%;
  }
  &__bottom-right {
    position: absolute;
    bottom: 0px;
    z-index: 10;
    right: 0px;
  }
  &__main-img {
    position: absolute;
    bottom: -20px;
    right: -450px;
    width: 1300px;
    z-index: 1;
  }
  &__bg-main-img {
    position: absolute;
    bottom: 20px;
    right: -100px;
    transition: 0.3s;
    opacity: 0;
    transform: scale(1.6);
  }

  &__text {
    margin-top: 130px;
    margin-bottom: 200px;
  }

  @media (max-width: 960px) {
    height: auto !important;
    &__text {
      margin-top: 80px;
      margin-bottom: 130px;
    }

    .hero-section__main {
      z-index: 0;
    }
  }
}
</style>
