<template>
  <div class="new-home-contact custom-zoom">
    <div class="py-6 max-width">
      <div
        class="d-flex justify-end"
        :class="$vuetify.display.smAndDown ? '' : 'px-14'"
      >
        <v-row class="relative" justify="center">
          <v-col cols="12" md="9" lg="7" xl="6">
            <div class="new-home-contact__content">
              <v-form ref="form" @submit.prevent="submitHandler">
                <span
                  class="text-black nunito-sans-black font-36 mb-6 d-block"
                  >{{ translate["contact.contactUs"] }}</span
                >
                <v-row>
                  <!-- first name -->
                  <v-col cols="12" sm="6">
                    <base-input
                      :outlined="false"
                      :placeholder="translate['contact.name']"
                      :title="translate['contact.name']"
                      :rules="[
                        (v) =>
                          (!!v && v.length > 3) || $t('rules.fieldRequired'),
                      ]"
                      :defaultSelected="obj.firstName"
                      @changeSelected="obj.firstName = $event"
                    ></base-input>
                  </v-col>

                  <!-- last name -->
                  <v-col
                    cols="12"
                    sm="6"
                    class="form.lastNamePlaceholder form.lastName"
                  >
                    <base-input
                      :outlined="false"
                      :placeholder="translate['contact.surname']"
                      :title="translate['contact.surname']"
                      :rules="[(v) => (!!v && v.length > 3) || '']"
                      :defaultSelected="obj.lastName"
                      @changeSelected="obj.lastName = $event"
                    ></base-input>
                  </v-col>

                  <!-- email -->
                  <v-col cols="12">
                    <base-input
                      :outlined="false"
                      :placeholder="translate['contact.email']"
                      :title="translate['contact.email']"
                      :rules="emailRules"
                      :defaultSelected="obj.email"
                      @changeSelected="obj.email = $event"
                    ></base-input>
                  </v-col>

                  <!-- message -->
                  <v-col cols="12">
                    <base-textarea
                      :outlined="false"
                      :placeholder="translate['contact.message']"
                      :title="translate['contact.message']"
                      :rules="[
                        (v) =>
                          (!!v && v.length > 3) || $t('rules.fieldRequire'),
                      ]"
                      :defaultSelected="obj.message"
                      @changeSelected="obj.message = $event"
                    ></base-textarea>
                  </v-col>

                  <v-col cols="12" class="d-flex align-center relative">
                    <v-checkbox
                      v-model="obj.agreeTermsAndConditions"
                      :rules="[(v) => !!v || $t('rules.mustTrue')]"
                      flat
                    ></v-checkbox>
                    <span
                      class="text-lightdarkblue font-14 d-inline-block new-home-contact__term mb-6"
                    >
                      {{ translate["contact.iAgree"] }}
                      <router-link to="/terms">
                        <span class="font-700 text-darkblue">{{
                          translate["contact.termsAndConditions"]
                        }}</span>
                      </router-link>
                    </span>
                  </v-col>

                  <div class="w-100">
                    <v-btn
                      elevation="0"
                      color="#F9C32D"
                      block
                      width="100%"
                      height="60"
                      type="submit"
                      :loading="loading"
                      class="radius-30"
                    >
                      <span class="font-20 text-black nunito-sans-black">{{
                        translate["newDesign.submit"]
                      }}</span>
                    </v-btn>
                  </div>
                </v-row>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  props: {},
  data: (vm) => ({
    valid: false,
    loading: false,
    terms: false,
    obj: { agreeTermsAndConditions: false },
    emailRules: [
      (v) => !!v || vm.$t("rules.emailRequired"),
      (v) => /.+@.+\..+/.test(v) || vm.$t("rules.emailValid"),
    ],
  }),
  computed: {
    ...mapGetters(["translate"]),
  },
  methods: {
    async submitHandler() {
      // let valid = this.$refs.contactForm.validate();
      // console.
      // if (!valid) {

      //   return;
      // }
      const { valid } = await this.$refs.form.validate();

      if (!valid) {
        this.$store.dispatch("showSnack", {
          text: this.$t("rules.checkAllFields"),
          color: "error",
        });
        return;
      }

      this.loading = true
      let { data } = await axios.post("/utilities/forms/contact-us", this.obj);
      this.loading = false
      
      if (data) {
        this.$store.dispatch("showSnack", {
          text: "Message Sent Successfully",
          color: "success",
        });
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something is wrong please try again later!",
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.new-home-contact {
  background: url("@/assets/images/new-design/contact/bg.png") center;
  background-size: cover;
  position: relative;
  padding: 100px 0px;

  .v-input--switch {
    .v-messages__wrapper {
      .v-messages__message {
        position: absolute !important;
        white-space: nowrap !important;
      }
    }
  }

  &__content {
    padding: 48px;
    border-radius: 24px;
    background: #fff;
  }

  &__term {
    width: -webkit-fill-available !important;
  }
  .v-messages {
    position: absolute;
    width: 250px;
  }

  @media (max-width: 960px) {
    &__content {
      padding: 50px 20px;
      position: relative;
      top: unset;
      left: unset;
      width: 100% !important;
      backdrop-filter: unset;
      transform: unset !important;
    }
    &__term {
      position: relative;
      left: 0px;
    }
  }
}
</style>
