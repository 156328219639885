<template>
  <div class="pb-8 max-width pt-4">
    <div class="py-6">
      <div
        class="home-contact d-flex justify-end"
        :class="$vuetify.display.smAndDown ? '' : 'px-14'"
      >
        <div
          class="relative"
          :class="$vuetify.display.mdAndDown ? 'pt-2' : 'w-50'"
        >
          <div class="home-contact__content">
            <v-form
              v-model="valid"
              ref="contactForm"
              @submit.prevent="submitHandler"
            >
              <span class="text-primary font-32 mb-6 d-block">{{
                translate["contact.contactUs"]
              }}</span>
              <v-row>
                <!-- first name -->
                <v-col cols="6">
                  <base-input
                    :outlined="false"
                    :placeholder="translate['contact.name']"
                    :title="translate['contact.name']"
                    :rules="[
                      (v) => (!!v && v.length > 3) || $t('rules.fieldRequired'),
                    ]"
                    :defaultSelected="obj.firstName"
                    @changeSelected="obj.firstName = $event"
                  ></base-input>
                </v-col>

                <!-- last name -->
                <v-col cols="6" class="form.lastNamePlaceholder form.lastName">
                  <base-input
                    :outlined="false"
                    :placeholder="translate['contact.surname']"
                    :title="translate['contact.surname']"
                    :rules="[(v) => (!!v && v.length > 3) || '']"
                    :defaultSelected="obj.lastName"
                    @changeSelected="obj.lastName = $event"
                  ></base-input>
                </v-col>

                <!-- email -->
                <v-col cols="12">
                  <base-input
                    :outlined="false"
                    :placeholder="translate['contact.email']"
                    :title="translate['contact.email']"
                    :rules="emailRules"
                    :defaultSelected="obj.email"
                    @changeSelected="obj.email = $event"
                  ></base-input>
                </v-col>

                <!-- message -->
                <v-col cols="12">
                  <base-textarea
                    :outlined="false"
                    :placeholder="translate['contact.message']"
                    :title="translate['contact.message']"
                    :rules="[
                      (v) => (!!v && v.length > 3) || $t('rules.fieldRequire'),
                    ]"
                    :defaultSelected="obj.message"
                    @changeSelected="obj.message = $event"
                  ></base-textarea>
                </v-col>

                <v-col cols="12" class="d-flex align-center relative">
                  <v-checkbox
                    v-model="obj.agreeTermsAndConditions"
                    :rules="[(v) => !!v || $t('rules.mustTrue')]"
                    flat
                  ></v-checkbox>
                  <span
                    class="text-lightdarkblue font-14 d-inline-block home-contact__term mx-6 mb-6"
                  >
                    {{ translate["contact.iAgree"] }}
                    <router-link to="/terms">
                      <span class="font-700 text-darkblue">{{
                        translate["contact.termsAndConditions"]
                      }}</span>
                    </router-link>
                  </span>
                </v-col>

                <div
                  class="w-100"
                  :class="$vuetify.display.mdAndDown ? '' : 'px-10'"
                >
                  <v-btn
                    elevation="0"
                    color="primary"
                    block
                    width="100%"
                    height="50px"
                    type="submit"
                    class="radius-5"
                  >
                    <span class="font-20">{{
                      translate["contact.sendTheMessage"]
                    }}</span>
                  </v-btn>
                </div>
              </v-row>
            </v-form>
          </div>
        </div>
        <img
          v-if="!$vuetify.display.mdAndDown"
          class="home-contact__bg object-cover"
          width="100%"
          height="700"
          :src="contactImage"
          alt="contact-bg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import contactImage from "@/assets/images/global/contact.jpeg";
export default {
  setup() {
    return {
      contactImage,
    };
  },
  props: {
    isPage: {
      type: Boolean,
      default: false,
    },
  },
  data: (vm) => ({
    valid: false,
    terms: false,
    obj: { agreeTermsAndConditions: false },
    emailRules: [
      (v) => !!v || vm.$t("rules.emailRequired"),
      (v) => /.+@.+\..+/.test(v) || vm.$t("rules.emailValid"),
    ],
  }),
  computed: {
    ...mapGetters(["translate"]),
  },
  methods: {
    async submitHandler() {
      let valid = this.$refs.contactForm.validate();
      if (!valid) {
        this.$store.dispatch("showSnack", {
          text: this.$t("rules.checkAllFields"),
          color: "error",
        });
        return;
      }
      let { data } = await this.$axios.post(
        "/utilities/forms/contact-us",
        this.obj
      );
      if (data) {
        this.$store.dispatch("showSnack", {
          text: "Message Sent Successfully",
          color: "success",
        });
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something is wrong please try again later!",
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.home-contact {
  .v-input--switch {
    .v-messages__wrapper {
      .v-messages__message {
        position: absolute !important;
        white-space: nowrap !important;
      }
    }
  }
  &__bg {
    border-radius: 12px;
    width: 95%;
    width: 600px;
    max-height: 700px;
    border-radius: 25px;
    overflow: hidden;
  }

  &__content {
    padding: 40px 100px 70px 100px;
    border-radius: 12px;
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 680px;
    background: rgba(255, 255, 255, 0.8);
    /* Background Bulr */

    backdrop-filter: blur(48px);
  }

  &__term {
    position: absolute;
    left: 28px;
  }

  @media (max-width: 960px) {
    &__content {
      padding: 50px 20px;
      position: relative;
      top: unset;
      left: unset;
      width: 100% !important;
      backdrop-filter: unset;
      transform: unset !important;
    }
    &__term {
      position: relative;
      left: 0px;
    }
  }
}
</style>
