<template>
  <div class="login-form w-100 h-100">
    <div class="d-none" id="loadUrl"></div>
    <v-row class="w-100 h-100 ma-0" align="center">
      <v-col
        md="6"
        v-if="!$vuetify.display.smAndDown"
        class="pa-0 img-bg relative"
      >
        <div class="px-8 h-100 d-grid">
          <div class="px-3">
            <h2 class="font-50 darkblue2--text mt-4 arial-bold">
              {{ translate["register.title"] }}
            </h2>
            <p class="darkblue2--text font-16">
              {{ translate["register.titleDesc"] }}
            </p>
            <NuxtLink to="/register">
              <base-button
                :loading="loading"
                :title="translate['register.getStarted']"
                classes="radius-5 mt-6"
                height="40px"
                :outlined="true"
                @click="goToRegister"
              ></base-button>
            </NuxtLink>
          </div>
          <img src="/images/global/login-bg.png" alt="login-bg" />
        </div>
      </v-col>
      <v-col md="6" class="pa-0" cols="12">
        <div class="white pa-5">
          <div class="d-flex justify-end mb-7">
            <img
              class="login-form__close cursor-pointer"
              @click="$emit('close')"
              src="/images/icons/close.svg"
              alt="close"
            />
          </div>

          <h3 class="font-32 black--text">
            {{ translate["login.welcomeToBondai"] }}
          </h3>
          <!-- <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-center">
              <img
                :src="require('@/assets/images/social/facebook-login.svg')"
                alt=""
              />
              <span class="mx-3 darkblue2--text font-16"
                >Sign in with Facebook</span
              >
            </div>
            <div class="d-flex align-center">
              <img
                :src="require('@/assets/images/social/google-login.svg')"
                alt=""
              />
              <span class="mx-3 darkblue2--text font-16"
                >Sign in with Google</span
              >
            </div>
          </div> -->

          <!-- or line -->
          <!-- <div class="login-form__or d-flex align-center mt-5">
            <div class="login-form__or__line "></div>
            <span class="lightgreyText--text font-12 mx-4">or</span>
            <div class="login-form__or__line "></div>
          </div> -->

          <!-- form -->
          <v-form
            class="mt-4"
            v-model="valid"
            @submit.prevent="submitHandler"
            ref="userForm"
          >
            <!-- first name -->
            <v-row>
              <v-col cols="12">
                <base-input
                  :placeholder="translate['form.usernameOrEmail']"
                  :title="translate['form.usernameOrEmail']"
                  :rules="[
                    (v) =>
                      (!!v && v.length > 3) || translate['rules.fieldRequire'],
                  ]"
                  :defaultSelected="obj.username"
                  @changeSelected="obj.username = $event"
                ></base-input>
              </v-col>
              <v-col cols="12">
                <base-input
                  placeholder="*********"
                  :title="translate['form.password']"
                  :customIcon="true"
                  :type="showPassword ? 'text' : 'password'"
                  :appendIcon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @appendClicked="showPassword = !showPassword"
                  :defaultSelected="obj.password"
                  @changeSelected="obj.password = $event"
                ></base-input>
                <!-- :rules="passwordRules" -->
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  v-model="rememberMe"
                  :hide-details="true"
                  dense
                  :label="translate['register.rememberMe']"
                ></v-checkbox>
              </v-col>

              <v-col cols="12">
                <base-button
                  :loading="loading"
                  width="100%"
                  :title="translate['form.login']"
                  classes="radius-5  "
                  type="submit"
                  height="44px"
                  color="#FD8467"
                ></base-button>
              </v-col>

              <v-col cols="12" class="text-center">
                <span
                  class="font-16 darkblue2--text cursor-pointer"
                  @click="forgetPassword"
                  >{{ translate["register.forgetPassword"] }}</span
                >
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  setup(props, context) {

    const goToRegister = () => {
      context.emit("close");
    };

    return {  goToRegister };
  },
  data: () => ({
    obj: {},
    loading: false,
    showPassword: false,
    rememberMe: false,
    valid: false,
    passwordRules: [
      (v) =>
        (!!v && v.length > 7) ||
        "Password should include at least 8 characters, at least 1 number, at least 1 capital letter",
      (v) =>
        /^(?=.*\d)(?=.*[A-Z]).{8,40}$/.test(v) ||
        "Password should include at least 8 characters, at least 1 number, at least 1 capital letter",
    ],
  }),
  computed: {
    translate() {
      return this.store.$state.translate;
    },
  },
  methods: {
    forgetPassword() {
      this.$emit("reset");
    },
    load(url) {
      window.open(url);
    },
    async submitHandler() {
      let valid = this.$refs.userForm.validate();
      if (!valid) {
        this.$store.dispatch('showSnack', ({
          text: this.translate["rules.checkAllFields"],
          color: "error",
        }));
        return;
      }

      this.loading = true;
      let res = await axios.post("/tc-users/sign-in", this.obj);
      this.loading = false;
      if (res.data && res.data.success) {
        this.$store.dispatch("showSnack", {
          text: this.translate["messages.userLogged"],
          color: "success",
        });
        this.store.setUser(res.data.value);
        if (this.rememberMe) {
          localStorage.setItem("userName", res.data.value.agencyName);
        }

        // this.load(res.data.value.authenticateUrl, "loadUrl");
        this.$emit("close");
      } else {
        let error = "";
        if (res.Errors && res.Errors.length > 0) {
          error = this.translate[res.Errors[0].Message];
        }
        if (res.errors && res.errors.length > 0) {
          error = this.translate[res.errors[0].message];
        }
        this.$store.dispatch("showSnack", {
          text: error,
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.login-form {
  box-shadow: 0px 2px 25px 0px #0000001a;
  border-radius: 24px;
  position: relative;

  &__or {
    &__line {
      background: #dbdde0;
      width: 100%;
      height: 1px;
      margin-top: 2px;
    }
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
  }
  .img-bg {
    img {
    }
  }
}
</style>
